var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select_opt_area",
      attrs: { id: "flexi_area_" + _vm.cntrSeq },
    },
    [
      _c("div", { staticClass: "select_opt_area mt10" }, [
        _c("table", { staticClass: "tbl_col t2" }, [
          _vm._m(0),
          _c("tbody", [
            _vm._m(1),
            _c("tr", [
              _c("td", { attrs: { colspan: "2" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.flexiInfo.cmdtDsc,
                      expression: "flexiInfo.cmdtDsc",
                    },
                  ],
                  attrs: { id: "cmdtDsc_" + _vm.cntrSeq, type: "text" },
                  domProps: { value: _vm.flexiInfo.cmdtDsc },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationFlexi("cmdtDsc")
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.flexiInfo, "cmdtDsc", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", { attrs: { colspan: "2" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.flexiInfo.mfcoNm,
                      expression: "flexiInfo.mfcoNm",
                    },
                  ],
                  attrs: { id: "mfcoNm_" + _vm.cntrSeq, type: "text" },
                  domProps: { value: _vm.flexiInfo.mfcoNm },
                  on: {
                    keyup: function ($event) {
                      return _vm.checkValidationFlexi("mfcoNm")
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.flexiInfo, "mfcoNm", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._m(2),
            _c("tr", [
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flexiInfo.dgYn,
                        expression: "flexiInfo.dgYn",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.flexiInfo,
                            "dgYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.changeDgYn,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "Y" } }, [_vm._v("Yes")]),
                    _c("option", { attrs: { value: "N" } }, [_vm._v("No")]),
                  ]
                ),
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flexiInfo.mpltYn,
                        expression: "flexiInfo.mpltYn",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.flexiInfo,
                            "mpltYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.changeDgYn,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "Y" } }, [_vm._v("Yes")]),
                    _c("option", { attrs: { value: "N" } }, [_vm._v("No")]),
                  ]
                ),
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flexiInfo.newFbYn,
                        expression: "flexiInfo.newFbYn",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.flexiInfo,
                            "newFbYn",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.changeNewFbYn,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "Y" } }, [_vm._v("Yes")]),
                    _c("option", { attrs: { value: "N" } }, [_vm._v("No")]),
                  ]
                ),
              ]),
              _c(
                "td",
                [
                  _c("e-input-number", {
                    class:
                      _vm.flexiInfo.flsTmpr !== "" && _vm.flexiInfo.flsTmpr < 60
                        ? "bg_pk col_9"
                        : "col_9",
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.flexiInfo.flsTmpr,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "flsTmpr", $$v)
                      },
                      expression: "flexiInfo.flsTmpr",
                    },
                  }),
                  _vm._v(" ℃ "),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("th", { attrs: { colspan: "2", scope: "col" } }, [
                _vm._v(" Flexibag MAX. Loadable Capacity "),
                _c("span", { staticClass: "position_relative span_tooltip" }, [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon help",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$ekmtcCommon.showTooltip($event)
                        },
                      },
                    },
                    [_vm._v("도움말")]
                  ),
                  _c(
                    "div",
                    { staticClass: "tooltip_wrap short position_absolute" },
                    [
                      _c("div", { staticClass: "cont" }, [
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$ekmtcCommon.hideTooltip($event)
                              },
                            },
                          },
                          [_vm._v("close")]
                        ),
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.141")) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("th", { attrs: { scope: "col" } }, [
                _vm._v(" Actual Loading Volume "),
                _c("span", { staticClass: "position_relative span_tooltip" }, [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon help",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$ekmtcCommon.showTooltip($event)
                        },
                      },
                    },
                    [_vm._v("도움말")]
                  ),
                  _c(
                    "div",
                    { staticClass: "tooltip_wrap short position_absolute" },
                    [
                      _c("div", { staticClass: "cont" }, [
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$ekmtcCommon.hideTooltip($event)
                              },
                            },
                          },
                          [_vm._v("close")]
                        ),
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.138"))),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.139")) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("th", { attrs: { scope: "col" } }, [_vm._v("Loading Ratio")]),
            ]),
            _c("tr", [
              _c("td", { attrs: { colspan: "2" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flexiInfo.fbCapa,
                        expression: "flexiInfo.fbCapa",
                      },
                    ],
                    staticClass: "col_8",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.flexiInfo,
                            "fbCapa",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.fnFbWeightChk()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "14000" } }, [
                      _vm._v("14,000"),
                    ]),
                    _c("option", { attrs: { value: "16000" } }, [
                      _vm._v("16,000"),
                    ]),
                    _c("option", { attrs: { value: "17500" } }, [
                      _vm._v("17,500 "),
                    ]),
                    _c("option", { attrs: { value: "18000" } }, [
                      _vm._v("18,000"),
                    ]),
                    _c("option", { attrs: { value: "19000" } }, [
                      _vm._v("19,000"),
                    ]),
                    _c("option", { attrs: { value: "20000" } }, [
                      _vm._v("20,000"),
                    ]),
                    _c("option", { attrs: { value: "21000" } }, [
                      _vm._v("21,000"),
                    ]),
                    _c("option", { attrs: { value: "22000" } }, [
                      _vm._v("22,000"),
                    ]),
                    _c("option", { attrs: { value: "23000" } }, [
                      _vm._v("23,000 "),
                    ]),
                    _c("option", { attrs: { value: "24000" } }, [
                      _vm._v("24,000"),
                    ]),
                  ]
                ),
                _vm._v(" liter "),
              ]),
              _c(
                "td",
                [
                  _c("e-input-number", {
                    attrs: {
                      id: "fbGrsWt_" + _vm.cntrSeq,
                      "input-class": "col_8",
                      "is-comma": true,
                      maxlength: 18,
                    },
                    on: {
                      input: function ($event) {
                        _vm.fnFbWeightChk()
                        _vm.checkValidationFlexi("fbGrsWt")
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.fbGrsWt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "fbGrsWt", $$v)
                      },
                      expression: "flexiInfo.fbGrsWt",
                    },
                  }),
                  _vm._v(" liter "),
                ],
                1
              ),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.flexiInfo.perMax,
                      expression: "flexiInfo.perMax",
                    },
                  ],
                  staticClass: "col_8",
                  attrs: {
                    id: "perMax_" + _vm.cntrSeq,
                    type: "text",
                    readonly: "",
                  },
                  domProps: { value: _vm.flexiInfo.perMax },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.flexiInfo, "perMax", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._m(3),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "1" } },
                [
                  _c("e-date-picker", {
                    staticClass: "margin-left",
                    attrs: { id: "insrCertStrDt_" + _vm.cntrSeq },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationFlexi("insrCertStrDt", $event)
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.insrCertStrDt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "insrCertStrDt", $$v)
                      },
                      expression: "flexiInfo.insrCertStrDt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                { attrs: { colspan: "1" } },
                [
                  _c("e-date-picker", {
                    staticClass: "margin-left",
                    attrs: { id: "insrCertEndDt_" + _vm.cntrSeq },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationFlexi("insrCertEndDt", $event)
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.insrCertEndDt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "insrCertEndDt", $$v)
                      },
                      expression: "flexiInfo.insrCertEndDt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                { attrs: { colspan: "1" } },
                [
                  _c("e-date-picker", {
                    staticClass: "margin-left",
                    attrs: { id: "coaCertStrDt_" + _vm.cntrSeq },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationFlexi("coaCertStrDt", $event)
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.coaCertStrDt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "coaCertStrDt", $$v)
                      },
                      expression: "flexiInfo.coaCertStrDt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                { attrs: { colspan: "1" } },
                [
                  _c("e-date-picker", {
                    staticClass: "margin-left",
                    attrs: { id: "coaCertEndDt_" + _vm.cntrSeq },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationFlexi("coaCertEndDt", $event)
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.coaCertEndDt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "coaCertEndDt", $$v)
                      },
                      expression: "flexiInfo.coaCertEndDt",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._m(4),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "1" } },
                [
                  _c("e-date-picker", {
                    staticClass: "margin-left",
                    attrs: { id: "railTestDt_" + _vm.cntrSeq },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationFlexi("railTestDt", $event)
                      },
                    },
                    model: {
                      value: _vm.flexiInfo.railTestDt,
                      callback: function ($$v) {
                        _vm.$set(_vm.flexiInfo, "railTestDt", $$v)
                      },
                      expression: "flexiInfo.railTestDt",
                    },
                  }),
                ],
                1
              ),
              _c("td", { attrs: { colspan: "3" } }),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v(" File Upload ")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c("div", { staticClass: "filebox" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      class: _vm.isFileUpload() ? "blue" : "gray file",
                      on: {
                        click: function ($event) {
                          return _vm.openUploadPopup()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.205")) + " ")]
                  ),
                  _vm.loiDownYn === "Y"
                    ? _c(
                        "a",
                        {
                          class: _vm.$ekmtcCommon.isEmpty(_vm.detailBkgNo)
                            ? "button sm gray file"
                            : "button sm blue",
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadLOI()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.NEWB010P140.005")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [
                _vm._v(" Request Remark "),
              ]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.flexiInfo.flexiCont,
                      expression: "flexiInfo.flexiCont",
                    },
                  ],
                  domProps: { value: _vm.flexiInfo.flexiCont },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.flexiInfo, "flexiCont", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v("status")]),
              _c("td", [
                _c("span", { staticClass: "span_scg" }, [
                  _vm._v(_vm._s(_vm.flexiInfo.apvStsNm)),
                ]),
              ]),
              _c("th", { attrs: { scope: "row" } }, [_vm._v("Ref No.")]),
              _c("td", [
                _c("span", { staticClass: "span_scg" }, [
                  _vm._v(_vm._s(_vm.flexiInfo.scgAppNo)),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("th", { attrs: { scope: "row" } }, [_vm._v(" KMTC Remark ")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _c("textarea", {
                  staticClass: "txt_border_none",
                  attrs: { readonly: "" },
                  domProps: { value: _vm.flexiInfo.apvCont },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "desc_list" }, [
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.211")))]),
            _c("li", [
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.212"))),
              _c("br"),
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.213"))),
            ]),
            _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.512")))]),
          ]),
        ]),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "popup_dim_upload" },
        [
          _vm.uploadComponent
            ? _c(_vm.uploadComponent, {
                tag: "component",
                attrs: {
                  "parent-info": _vm.uploadParam,
                  "category-info": _vm.uploadCategoryInfo,
                },
                on: {
                  close: _vm.closeUploadPopup,
                  "set-dext-file-info": _vm.uploadCallbackFunc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _vm._v("Commodity"),
      ]),
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _vm._v("COA-Supplied Name"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v(" Hazardous ")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v(" Marine Pollutant ")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v(" New Flexible or Not ")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v(" Flash Point ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { colspan: "1", scope: "col" } }, [
        _vm._v("Insurance Cert' Start"),
      ]),
      _c("th", { attrs: { colspan: "1", scope: "col" } }, [
        _vm._v("Insurance Cert' End"),
      ]),
      _c("th", { attrs: { colspan: "1", scope: "col" } }, [
        _vm._v("COA membership Cert Start"),
      ]),
      _c("th", { attrs: { colspan: "1", scope: "col" } }, [
        _vm._v("COA membership Cert End"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { colspan: "1", scope: "col" } }, [
        _vm._v("Rail Impact Test Report Date"),
      ]),
      _c("th", { attrs: { colspan: "3", scope: "col" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }