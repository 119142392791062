<template>
  <div class="select_opt_area" :id="'flexi_area_' + cntrSeq">
    <!-- Case03. Flexi Bag 시작 -->
    <div class="select_opt_area mt10">
      <table class="tbl_col t2">
        <colgroup>
          <col style="width:25%">
          <col style="width:25%">
          <col style="width:25%">
          <col style="width:auto">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="2" scope="col">Commodity</th>
            <th colspan="2" scope="col">COA-Supplied Name</th>
          </tr>
          <tr>
            <td colspan="2">
              <input
                v-model="flexiInfo.cmdtDsc"
                :id="'cmdtDsc_' + cntrSeq"
                type="text"
                @keyup="checkValidationFlexi('cmdtDsc')"
              >
            </td>
            <td colspan="2">
              <input
                v-model="flexiInfo.mfcoNm"
                :id="'mfcoNm_' + cntrSeq"
                type="text"
                @keyup="checkValidationFlexi('mfcoNm')"
              >
            </td>
          </tr>
          <tr>
            <th scope="col">
              Hazardous
            </th>
            <th scope="col">
              Marine Pollutant
            </th>
            <th scope="col">
              New Flexible or Not
            </th>
            <th scope="col">
              Flash Point
            </th>
          </tr>
          <tr>
            <td>
              <select
                v-model="flexiInfo.dgYn"
                @change="changeDgYn"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <select
                v-model="flexiInfo.mpltYn"
                @change="changeDgYn"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <select
                v-model="flexiInfo.newFbYn"
                @change="changeNewFbYn"
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td>
              <e-input-number
                v-model="flexiInfo.flsTmpr"
                :class="flexiInfo.flsTmpr !== '' && flexiInfo.flsTmpr < 60 ? 'bg_pk col_9' : 'col_9'"
                @input="handleChange"
              >
              </e-input-number> ℃
            </td>
          </tr>
          <tr>
            <th colspan="2" scope="col">
              Flexibag MAX. Loadable Capacity
              <span class="position_relative span_tooltip">
                <button
                  type="button"
                  class="tbl_icon help"
                  @click="$ekmtcCommon.showTooltip($event)"
                >도움말</button>
                <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button
                      type="button"
                      class="close"
                      @click="$ekmtcCommon.hideTooltip($event)"
                    >close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        {{ $t('msg.CSBK100.141') }}
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </th>
            <th scope="col">
              Actual Loading Volume
              <span class="position_relative span_tooltip">
                <button
                  type="button"
                  class="tbl_icon help"
                  @click="$ekmtcCommon.showTooltip($event)"
                >도움말</button>
                <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button
                      type="button"
                      class="close"
                      @click="$ekmtcCommon.hideTooltip($event)"
                    >close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        {{ $t('msg.CSBK100.138') }}<br>
                        {{ $t('msg.CSBK100.139') }}
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </th>
            <th scope="col">Loading Ratio</th>
          </tr>
          <tr>
            <td colspan="2">
              <select
                v-model="flexiInfo.fbCapa"
                class="col_8"
                @change="fnFbWeightChk()"
              >
                <option value="14000">14,000</option>
                <option value="16000">16,000</option>
                <option value="17500">17,500 </option>
                <option value="18000">18,000</option>
                <option value="19000">19,000</option>
                <option value="20000">20,000</option>
                <option value="21000">21,000</option>
                <option value="22000">22,000</option>
                <option value="23000">23,000 </option>
                <option value="24000">24,000</option>
              </select>
              liter
            </td>
            <td>
              <e-input-number
                v-model="flexiInfo.fbGrsWt"
                :id="'fbGrsWt_' + cntrSeq"
                input-class="col_8"
                :is-comma="true"
                @input="fnFbWeightChk();checkValidationFlexi('fbGrsWt')"
                :maxlength="18"
              >
              </e-input-number>
              liter
            </td>
            <td>
              <input
                :id="'perMax_' + cntrSeq"
                v-model="flexiInfo.perMax"
                type="text"
                class="col_8"
                readonly
              >
            </td>
          </tr>
          <tr>
            <th colspan="1" scope="col">Insurance Cert' Start</th>
            <th colspan="1" scope="col">Insurance Cert' End</th>
            <th colspan="1" scope="col">COA membership Cert Start</th>
            <th colspan="1" scope="col">COA membership Cert End</th>
          </tr>
          <tr>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.insrCertStrDt"
                :id="'insrCertStrDt_' + cntrSeq"
                @input="checkValidationFlexi('insrCertStrDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                v-model="flexiInfo.insrCertStrDt"
                :id="'insrCertStrDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('insrCertStrDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.insrCertEndDt"
                :id="'insrCertEndDt_' + cntrSeq"
                @input="checkValidationFlexi('insrCertEndDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                v-model="flexiInfo.insrCertEndDt"
                :id="'insrCertEndDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('insrCertEndDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.coaCertStrDt"
                :id="'coaCertStrDt_' + cntrSeq"
                @input="checkValidationFlexi('coaCertStrDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                v-model="flexiInfo.coaCertStrDt"
                :id="'coaCertStrDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('coaCertStrDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.coaCertEndDt"
                :id="'coaCertEndDt_' + cntrSeq"
                @input="checkValidationFlexi('coaCertEndDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                v-model="flexiInfo.coaCertEndDt"
                :id="'coaCertEndDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('coaCertEndDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
          </tr>
          <tr>
            <th colspan="1" scope="col">Rail Impact Test Report Date</th>
            <th colspan="3" scope="col"></th>
          </tr>
          <tr>
            <td colspan="1">
              <e-date-picker
                v-model="flexiInfo.railTestDt"
                :id="'railTestDt_' + cntrSeq"
                @input="checkValidationFlexi('railTestDt', $event)"
                class="margin-left"
              >
              </e-date-picker>
              <!-- <input
                v-model="flexiInfo.railTestDt"
                :id="'railTestDt_' + cntrSeq"
                type="text"
                @input="checkValidationFlexi('railTestDt', $event)"
                placeholder="ex) YYYYMMDD"
                maxlength="10"
              > -->
            </td>
            <td colspan="3">
            </td>
          </tr>
          <tr>
            <th scope="row">
              File Upload
            </th>
            <td colspan="3">
              <div class="filebox">
                <a
                  @click="openUploadPopup()"
                  :class="isFileUpload() ? 'blue' : 'gray file'"
                  class="button sm"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <a
                  v-if="loiDownYn === 'Y'"
                  @click="downloadLOI()"
                  :class="$ekmtcCommon.isEmpty(detailBkgNo) ? 'button sm gray file' : 'button sm blue'"
                  style="margin-left: 10px;"
                >
                  {{ $t('msg.NEWB010P140.005') }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              Request Remark
            </th>
            <td colspan="3">
              <textarea v-model="flexiInfo.flexiCont" />
            </td>
          </tr>
          <tr>
            <th scope="row">status</th>
            <td>
              <span class="span_scg">{{ flexiInfo.apvStsNm }}</span>
            </td>
            <th scope="row">Ref No.</th>
            <td>
              <span class="span_scg">{{ flexiInfo.scgAppNo }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              KMTC Remark
            </th>
            <td colspan="3">
              <textarea
                class="txt_border_none"
                :value="flexiInfo.apvCont"
                readonly
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="desc_list">
        <ul>
          <li>{{ $t('msg.CSBK100.211') }}</li>
          <li>{{ $t('msg.CSBK100.212') }}<br>{{ $t('msg.CSBK100.213') }}</li>
          <li>{{ $t('msg.CSBK100.512') }}</li>
        </ul>
      </div>
    </div>
    <!-- Case03. Flexi Bag 끝 -->
    <win-layer-pop class="popup_dim_upload">
      <component
        v-if="uploadComponent"
        :is="uploadComponent"
        @close="closeUploadPopup"
        :parent-info="uploadParam"
        @set-dext-file-info="uploadCallbackFunc"
        :category-info="uploadCategoryInfo"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import EInputNumber from '../common/EInputNumber.vue'
import DgOogCerti from '@/api/rest/trans/dgOogCerti'
import srInquiry from '@/api/services/srInquiry'
import bookings from '@/api/rest/trans/bookings'
import EDatePicker from '@/components/common/EDatePicker'

export default {
  name: 'BookingTableFlat',
  components: {
    EInputNumber,
    EDatePicker,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'doc-upload-pop': () => import('@/pages/trans/popup/DocUploadPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    saveFlag: {
      type: String,
      default: 'ADD'
    },
    detailBkgNo: {
      type: String,
      default: ''
    },
    loiDownYn: {
      type: String,
      default: 'Y'
    }
  },
  data () {
    return {
      uploadComponent: null,
      uploadType: '',
      uploadParam: [],
      uploadCategoryInfo: [],
      commonCodes: {},
      cntrSeq: '',
      flexiInfo: {
        cmdtDsc: '',
        mfcoNm: '',
        dgYn: 'N',
        mpltYn: 'N',
        newFbYn: 'Y',
        flsTmpr: '',
        fbCapa: '24000',
        fbGrsWt: '',
        perMax: '',
        reqRno: '',
        flexiCont: '',
        insrCertStrDt: '',
        insrCertEndDt: '',
        coaCertStrDt: '',
        coaCertEndDt: '',
        railTestDt: '',
        uploadFileList: []
      }
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    handleChange () {
      console.log('@@@@ enter handleChange')
      console.log({
        ...this.flexiInfo
      })

      this.$emit('update', {
        ...this.flexiInfo
      }, this.index)
    },
    initPage () {
      this.initParams()
    },
    async initParams () {
      console.log('@@@@ initParams: ', this.parentInfo)
      this.flexiInfo = {
        cmdtDsc: this.parentInfo.cmdtDsc || '',
        mfcoNm: this.parentInfo.mfcoNm || '',
        dgYn: this.parentInfo.dgYn || 'N',
        mpltYn: this.parentInfo.mpltYn || 'N',
        newFbYn: this.parentInfo.newFbYn || 'Y',
        flsTmpr: this.parentInfo.flsTmpr || '',
        fbCapa: this.parentInfo.fbCapa || '24000',
        fbGrsWt: this.parentInfo.fbGrsWt || '',
        perMax: this.parentInfo.perMax || '',
        flexiCont: this.parentInfo.flexiCont || '',
        reqRno: this.parentInfo.reqRno || '',
        uploadFileList: this.parentInfo.uploadFileList || [],
        apvStsNm: this.parentInfo.apvStsNm || '',
        scgAppNo: this.parentInfo.scgAppNo || '',
        apvCont: this.parentInfo.apvCont || '',
        insrCertStrDt: this.parentInfo.insrCertStrDt || '',
        insrCertEndDt: this.parentInfo.insrCertEndDt || '',
        coaCertStrDt: this.parentInfo.coaCertStrDt || '',
        coaCertEndDt: this.parentInfo.coaCertEndDt || '',
        railTestDt: this.parentInfo.railTestDt || ''
      }

      this.cntrSeq = this.parentInfo.cntrSeq

      const arrCdId = ['VS040']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)

      // Flexi 첨부파일 카테고리
      const uploadCategoryInfo = this.commonCodes.VS040

      this.uploadCategoryInfo = uploadCategoryInfo.map(tvo => {
        const vo = {
          category: tvo.cdNm,
          value: tvo.cd
        }
        return vo
      })

      if (this.$ekmtcCommon.isEmpty(this.parentInfo.perMax) && this.$ekmtcCommon.isNotEmpty(this.parentInfo.fbGrsWt)) {
        this.$nextTick(() => {
          this.fnFbWeightChk()
        })
      }
    },
    checkValidationFlexiAll () {
      const prefix = ['cmdtDsc', 'mfcoNm', 'fbGrsWt', 'insrCertStrDt', 'insrCertEndDt', 'coaCertStrDt', 'coaCertEndDt']

      let isOk = true
      let failCnt = 0
      prefix.forEach(el => {
        if (!this.checkValidationFlexi(el)) {
          failCnt++
        }
      })

      if (failCnt > 0) {
        isOk = false
      }

      return isOk
    },
    checkValidationFlexi (prefix, payload) {
      let _payload = ''

      switch (prefix) {
        case 'insrCertStrDt':
        case 'insrCertEndDt':
        case 'coaCertStrDt':
        case 'coaCertEndDt':
        case 'railTestDt':
          if (payload) {
            // let formattedDate = payload.target.value.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
            _payload = payload
          }

          break
        default:
          _payload = payload
      }

      let isOk = true
      const frm = document.querySelector('#srForm')
      const id = prefix + '_' + this.cntrSeq
      const selector = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (prefix === 'cmdtDsc') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (!this.$ekmtcCommon.checkTextByte(selector.value, 2000)) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Too large text to create S/R.')
          isOk = false
        }
      }
      if (prefix === 'mfcoNm') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (!this.$ekmtcCommon.checkTextByte(selector.value, 100)) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Too large text to create S/R.')
          isOk = false
        }
      }
      if (prefix === 'fbGrsWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
      if (prefix === 'insrCertStrDt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
       if (prefix === 'insrCertEndDt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (prefix === 'coaCertStrDt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
      if (prefix === 'coaCertEndDt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
      /*
      if (prefix === 'railTestDt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
      */
      this.handleChange()

      return isOk
    },
    changeDgYn (e) {
      const val = e.target.value

      if (val === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.021'))
      }

      this.handleChange()
    },
    changeNewFbYn (e) {
      const val = e.target.value

      if (val === 'N') {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.022'))
      }

      this.handleChange()
    },
    fnFbWeightChk () {
      const flexiInfo = this.flexiInfo
      const fbCapa = flexiInfo.fbCapa
      let fbGrsWt = flexiInfo.fbGrsWt
      let perMax = ''
      if (fbGrsWt !== '' && fbGrsWt !== '0') {
        fbGrsWt = Number(fbGrsWt.replace(/,/g, ''))
        perMax = Math.round(Number(fbGrsWt) / fbCapa * 1000) / 10.0
      }

      document.querySelector('#perMax_' + this.cntrSeq).value = perMax
      this.flexiInfo.perMax = perMax

      if (perMax !== '' && (perMax < 95 || perMax >= 105)) {
        document.querySelector('#perMax_' + this.cntrSeq).className = 'bg_pk col_8'
      } else {
        document.querySelector('#perMax_' + this.cntrSeq).className = 'col_8'
      }

      this.handleChange()
    },
    async openUploadPopup () {
      if (!this.$ekmtcCommon.isEmpty(this.flexiInfo.reqRno) && !this.$ekmtcCommon.isEmpty(this.detailBkgNo)) {
        const srContainerFileInfo = await srInquiry.getSrContainerFileInfo(this.flexiInfo.reqRno, this.detailBkgNo, 'F')

        this.flexiInfo.uploadFileList = [...[], ...srContainerFileInfo.data.uploadFileList]
      }

      this.flexiInfo.uploadFileList.map(obj => {
        obj.size = obj.fileSize
        obj.fileId = 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + obj.reqRno + '&atchFileSeq=' + obj.atchFileSeq + '&type=DG_OOG_CERTI_BK'
        obj.fileSeq = obj.atchFileSeq
      })

      this.uploadParam = [...[], ...this.flexiInfo.uploadFileList]

      this.uploadComponent = 'doc-upload-pop'
      this.$ekmtcCommon.layerOpen('#flexi_area_' + this.cntrSeq + ' > .popup_dim')
    },
    async uploadCallbackFunc (fileList) {
      if (!this.$ekmtcCommon.isEmpty(this.detailBkgNo) && !this.$ekmtcCommon.isEmpty(this.cntrSeq)) {
        const uploadFileList = this.$_.cloneDeep(fileList)

        uploadFileList.forEach(element => {
          if (element.newFlag === undefined || element.newFlag === '') {
            element.fileId = ''
          }
        })

        const dgOogParam = {
          reqRno: this.flexiInfo.reqRno,
          count: this.cntrSeq,
          type: 'F',
          dgUpLoadFlag: 'EDIT',
          bkgNo: this.detailBkgNo,
          uploadFileInfos: uploadFileList
        }

        const resultObject = await DgOogCerti.bookingSrPopDgOogUpload(dgOogParam)

        if (resultObject.data.resultCode === 'SUCC') {
          fileList.forEach(element => {
            element.fileId = ''
            delete element.newFlag
            delete element.new
          })

          this.flexiInfo.reqRno = resultObject.data.reqRno
        }
      }

      this.flexiInfo.uploadFileList = [...[], ...fileList]

      this.$ekmtcCommon.layerClose('#flexi_area_' + this.cntrSeq + ' > .popup_dim')

      this.handleChange()
    },
    isFileUpload () {
      if (this.flexiInfo.uploadFileList !== undefined && this.flexiInfo.uploadFileList.length > 0) {
        return true
      }
      return false
    },
    closeUploadPopup () {
      this.$ekmtcCommon.layerClose('#flexi_area_' + this.cntrSeq + ' > .popup_dim')
    },
    downloadLOI () {
      if (!this.$ekmtcCommon.isEmpty(this.detailBkgNo) && this.saveFlag !== 'ADD') {
        let bkgNo = this.detailBkgNo
        let cntrSeq = this.cntrSeq

        bookings.getflexibagLoi(bkgNo, cntrSeq).then(async (result) => {
          const fileName = 'KMTC_' + bkgNo + '_LOI.xlsx'
          //blob 객체 생성
          const blob = new Blob([result.data], { type: result.headers['content-type'] })
          if (blob.size === 0) {
            this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
          } else {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              //IE에서 동작
              window.navigator.msSaveOrOpenBlob(result.data, fileName)
            } else {
              //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')

              link.href = url
              link.target = '_self'
              link.download = fileName
              link.click()

              //url 사용 후 메모리에서 제거
              window.URL.revokeObjectURL(url)
            }
          }
        }).catch((err) => {
            console.log(err)
            this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.restriction.024'))
      }
    }
  }
}
</script>

<style>
  .bg_pk {background-color: #ffc6c6 !important; color: #909090 !important;}
  .actual_alert {height: auto !important; min-height: 350px;}
  .tooltip_wrap {min-width:300px; right:0; top:24px; display:none; text-align: left;}
  .tooltip_wrap .cont {word-break: keep-all;}
  .margin-left {
    margin-left: 80px !important;
  }
</style>
